@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

@import 'ngx-toastr/toastr';

.toast-container .ngx-toastr {
    background-image: none !important;
    padding: 15px;
    background-color: rgb(0 0 0 / 90%);
    border-radius: 0.5rem;
    box-shadow: none;
    border: 2px solid #ffffff;
}

.toast-title {
    margin-bottom: 5px;
}

*,
*::before,
*::after {
    box-sizing: border-box;
}

* {
    margin: 0;
    font-family: 'roboto';
}

html,
body {
    height: 100%;
    min-height: 844px;
}

img,
picture,
video,
canvas,
svg {
    display: block;
    max-width: 100%;
}

body {
    background: linear-gradient(179.16deg, #222222 0.72%, #7F1690 36.17%, #A80BF1 71.05%, #240533 96.92%);
    position: relative;
}

h1 {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 600;
    font-size: 52px;
    line-height: 55px;
    text-align: center;
    color: #FFFFFF;
    text-shadow: 0px 4px 11px #EB19BB;
}

h2 {
    color: #ffffff;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    text-align: center;
}

p {
    color: #ffffff;
}

a {
    text-decoration: none;
}

.flex-wrapper {
    height: 100%;
    width: 100%;
    display: flex;
}

.flex-container {
    display: flex;
    position: relative;
    background-image: url("https://storage.googleapis.com/petaverse-website-assets/catGPT/v1/bg-vectors.png");
    background-size: cover;
    flex-direction: column;
    width: 100%;
    max-width: 600px;
    height: 100%;
    min-height: 844px;
    max-height: 950px;
    margin: auto;
    overflow: hidden;

    @media (min-width: 600px) {
        background-color: rgb(255 255 255 / 10%);
        border-radius: 1.5rem;
    }
}

.content {
    width: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;

    &.no-pets {
        padding: 2rem;
        overflow: scroll;
        margin-top: 3rem;
    }
}

.story-container {
    flex: 0;
    border-radius: 0.5rem;
    background: rgba(255, 255, 255, 0.05);
    opacity: 0;
    transition: all 2s;
    height: 100%;
    overflow: scroll;
    padding: 1rem;
    border: 2px solid white;

    &.show {
        flex: 1;
        opacity: 1;
    }
}

pre {
    width: 100%;
    white-space: break-spaces;
    color: #ffffff;
    margin: 0;
    line-height: 1.45rem;
    padding: 0 0 1rem 0;

    &:not(:first-child) {
        padding: 1rem 0;
        border-top: 1px dashed #ffffff;
    }
}

.summary {
    h2 {
        padding: 0.5rem 0;
        font-size: 21px;
        line-height: 55px;
        text-shadow: 0px 4px 11px #EB19BB;
    }

    a{
        text-decoration: underline;
        font-weight: bold;
        color: #ffffff;
    }

    p {
        font-size: 18px;
        font-weight: bold;
    }

    pre {
        padding: 0.5rem 0 1rem 0;
        border: none;
    }


}

.finish-story,
.accept-terms {
    height: 56px;
    font-size: 16px;
    border: none;
    color: #ffffff;
    background: #272128;
    box-shadow: 0px 0px 20px #FCBFEE;
    border-radius: 12px;
    margin: 2rem 0;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

//components
.icon-button {
    background: #000000;
    border: none;
    //box-shadow: -1px 1px 5px 0px $petaverse-pink;
    border-radius: 8px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 60px;
    cursor: pointer;
    color: #ffffff;
    margin: 30px 0;

    span {
        color: #ffffff !important;
    }

    .icon {
        margin-right: 12px;
    }
}

.button {
    text-align: center;
    padding: 0.1rem;
    border-radius: 3rem;
    margin-top: 1.5rem;
    background: rgb(255 255 255 / 20%);
}

::-webkit-scrollbar {
    display: none;
}